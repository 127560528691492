import { UrlType } from 'core-lib/types/Helper';
import { OurServicesType } from '../types/navbarLayout';

export const OurServices: OurServicesType[] = [
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123005083198.png',
    title: 'FASTag',
    redirectionUrl: '/fastag',
    urlType: UrlType.Internal,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123156657650.png',
    title: 'Buy Petrol',
    redirectionUrl: '/app/shop/parkplus-fuel',
    urlType: UrlType.External,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123223662893.png',
    title: 'Challan',
    redirectionUrl: '/e-challan',
    urlType: UrlType.Internal,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123745093282.png',
    title: 'Bill Payments',
    redirectionUrl: '/app/services/bill-payment/bill-categories',
    urlType: UrlType.External,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123352976013.png',
    title: 'Store',
    redirectionUrl: '/app/shop/parkplus-store',
    urlType: UrlType.External,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123330275580.png',
    title: 'Test Drive',
    redirectionUrl: '/app/new-cars-v2',
    urlType: UrlType.External,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/team/public/FS_20241220123312981183.png',
    title: 'Car Service',
    redirectionUrl: '/app/car-servicing',
    urlType: UrlType.External,
  },
  {
    icon: 'https://parkplus-bkt-img.parkplus.io/production/homepage/public/new_homepage_new_car_20231031182719751548.png',
    title: 'New Cars',
    redirectionUrl: '/new-cars',
    urlType: UrlType.Internal,
  },
];

export const BusinessProductsData = [
  {
    id: 6,
    attributes: {
      heroTitle: 'Smart Parking Solution',
      heroSubtitle:
        'Our Smart Parking Solution offers fast vehicle access within 3 seconds, automatic transaction recording for hassle-free payments, and real-time parking spot availability to minimize search time. With tenant-level slot management and reduced congestion, it ensures a smooth and efficient parking experience, making visits stress-free and convenient.',
      slug: 'smart-parking-solution',
      productImages: {
        data: [
          {
            id: 12591,
            attributes: {
              alternativeText: 'smart-parking-solution',
              url: 'https://strapi-file-uploads.parkplus.io/smart_parking_solution_5b05fe0b36.jpg',
            },
          },
        ],
      },
    },
  },
  {
    id: 5,
    attributes: {
      heroTitle: 'Access Management System',
      heroSubtitle:
        'Our Access Management System offers seamless entry for residents within 5 seconds, ensuring quick and secure access to residential complexes. The system allows you to efficiently manage flat-level inventories and monitor resident activities in real time through a user-friendly dashboard. With advanced features, it helps enhance security, improve operational efficiency, and simplify access management for large or small residential communities.',
      slug: 'access-management-system',
      productImages: {
        data: [
          {
            id: 12817,
            attributes: {
              alternativeText: 'Park+ Boom barrier',
              url: 'https://strapi-file-uploads.parkplus.io/Park_Boom_barrier_d64a6fca73.webp',
            },
          },
        ],
      },
    },
  },
  {
    id: 4,
    attributes: {
      heroTitle: 'FASTag Charging Solution',
      heroSubtitle:
        'Park+ offers seamless vehicle access with the FASTag Charging service, enabling quick entry in just 3 seconds. Enjoy multiple payment options (FASTag, UPI, card, or cash) and track real-time analytics through a dashboard. Secure payments and minimized wait times ensure a smooth parking experience, while access via physical or digital passes enhances convenience.',
      slug: 'fastag-charging-solution',
      productImages: {
        data: [
          {
            id: 12555,
            attributes: {
              alternativeText: 'fastag_charging_solution',
              url: 'https://strapi-file-uploads.parkplus.io/ce0798f1c9118859f81ec19b2a4f9fe9_19a187d313.jpg',
            },
          },
        ],
      },
    },
  },
  {
    id: 3,
    attributes: {
      heroTitle: 'Parking Guidance System',
      heroSubtitle:
        'Park+ offers a state-of-the-art Parking Guidance System designed specifically for businesses to streamline parking management. Our advanced technology provides real-time data on parking space availability, ensuring a hassle-free experience for both operators and users. Ideal for shopping malls, corporate offices, airports, and other large facilities, the system improves traffic flow, reduces congestion, and enhances overall efficiency.',

      slug: 'parking-guidance-system',

      productImages: {
        data: [
          {
            id: 12520,
            attributes: {
              alternativeText: 'Parking Managment system',
              url: 'https://strapi-file-uploads.parkplus.io/Parking_Managment_system_dbd4138b4a.webp',
            },
          },
        ],
      },
    },
  },
  {
    id: 1,
    attributes: {
      heroTitle: 'ANPR Solution',
      heroSubtitle:
        'ANPR (Automatic Number Plate Recognition) solution is a technology that reads and recognizes vehicle license plates in real time. The system consists of high-resolution cameras equipped with optical character recognition (OCR) software. These cameras capture images of the vehicle’s number plate and convert the data into machine-readable text. This information is then processed and stored for various uses such as law enforcement, parking management, or access control systems.',

      slug: 'anpr-solution',

      productImages: {
        data: [
          {
            id: 12524,
            attributes: {
              alternativeText: 'ANPR Solution',
              url: 'https://strapi-file-uploads.parkplus.io/Frame_1437252984_236372365e.webp',
            },
          },
        ],
      },
    },
  },
];
