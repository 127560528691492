import InitStore from 'core-lib/components/InitStore';
import NetworkInterceptor from 'core-lib/components/NetworkInterceptor';
import { updateReduxRestoreState } from 'core-lib/reduxStore/setting';
import { isServer, sendToGoogleAnalytics } from 'core-lib/utils/utils';
import { NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';
import { Provider, useDispatch, useSelector } from 'react-redux';
import DownloadAppBottomBanner from 'src/common/components/DownloadAppBottomBanner';
import { store } from 'src/common/reduxStore/store';
import 'src/components/image_viewer/image_viewer.scss';
import Organization from 'src/utils/schema/organizationSchema';
import 'ui/styles/app.scss';
import 'ui/styles/colors.scss';
import 'ui/styles/dayPicker.scss';
import 'ui/styles/modal.scss';
import 'ui/styles/slider.scss';
import 'ui/styles/theme.scss';

const ActivityRecorder = dynamic(
  () => import('core-lib/components/ActivityRecorder'),
  {
    ssr: false,
  }
);
const GenericUI = dynamic(() => import('core-lib/components/GenericUI'), {
  ssr: false,
});

const ImageViewer = dynamic(
  () => import('src/components/image_viewer/image_viewer')
);

const NativeAuthHandler = dynamic(
  () => import('core-lib/components/NativeAuthHandler'),
  {
    ssr: false,
  }
);

const NativeBackHandler = dynamic(
  () => import('core-lib/components/NativeBackHandler'),
  {
    ssr: false,
  }
);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!isServer() && process.env.ENV === 'production') {
    sendToGoogleAnalytics(metric, 'WEBSITE');
  }
}

function MyApp(props) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no,viewport-fit=cover"
        />
      </Head>
      <Provider store={store}>
        <AppWrapper {...props} />
      </Provider>
    </>
  );
}

const AppWrapper = ({ Component, pageProps }) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const layout = useSelector((state) => state.layout);

  const contains = !!router.query?.amp;
  const { bannerContent } = pageProps;

  const bannerData = {
    title: '',
    url: '',
  };
  if (!pageProps?.stickyBanner) {
    pageProps.stickyBanner = bannerData;
  }
  return (
    <>
      <DownloadAppBottomBanner bannerContent={bannerContent} />
      {layout.imageViewer.show && <ImageViewer />}
      <Component {...pageProps} />
      <GenericUI />
      <InitStore
        onDone={() => {
          dispatch(updateReduxRestoreState(true));
        }}
      />
      <NetworkInterceptor />
      <ActivityRecorder />
      <NativeAuthHandler />
      <NativeBackHandler />
      <Organization />
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            padding: '5px 8px',
            fontSize: '15px',
            color: '#5568a0',
            zIndex: 99999,
          },
          success: {
            duration: 3500,
            className: 'cmn_toast_container',
            icon: null,
          },
          error: {
            duration: 3500,
            className: 'cmn_toast_container',
            icon: null,
          },
        }}
      />
    </>
  );
};

export default MyApp;
