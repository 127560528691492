import { loginPopupUpdate } from 'auth/store/action';
import cn from 'classnames';
import { UrlType } from 'core-lib/types/Helper';
import {
  getFullName,
  getUserProfilePic,
  isWebView,
} from 'core-lib/utils/helpers';
import { isParkPlusWebView } from 'core-lib/utils/source';
import { redirectToAppStore } from 'core-lib/utils/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OurServices } from 'src/common/constants/navbar';
import { NavbarLayoutProps } from 'src/common/types/navbarLayout';
import BreadCrumbV2 from 'src/components/common/bread_crumbs_v2/bread_crumbs_v2';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';
import AuthorSchema from 'src/utils/schema/authorSchema';
import BlogArticle from 'src/utils/schema/blogArticleSchema';
import FinancialProductSchema from 'src/utils/schema/financialProductSchema';
import ItemListSchemaNew from 'src/utils/schema/itemListSchemaNew';
import MobileApplicationSchema from 'src/utils/schema/mobileApplication';
import NewsArticle from 'src/utils/schema/newsArticleSchema';
import ParkingFacility from 'src/utils/schema/parkingSchema';
import SiteNavigationElement from 'src/utils/schema/siteNavigationSchema';
import TableSchema from 'src/utils/schema/tableSchema';
import VehicleSchema from 'src/utils/schema/vehicleSchema';
import { AppBar, Button, Typography } from 'ui';
import Cross from 'ui/components/newIcons/Cross';
import Download3 from 'ui/components/newIcons/Download3';
import HamburgerMenu from 'ui/components/newIcons/HamburgerMenu';
import ParkplusLogo from 'ui/components/newIcons/ParkplusLogo';
import Footer from '../Footer';
import s from './NavbarLayout.module.scss';
import NavbarModal from './NavbarModal';

export const NavbarLayout: React.FunctionComponent<NavbarLayoutProps> = ({
  title,
  header,
  pageHeader = { show: true },
  footer,
  children,
  breadCrumb,
  mobileApplicationSchema,
  siteNavigationSchema,
  tableSchema,
  parkingSchema,
  financialSchema,
  newsArticle,
  blogArticle,
  authorSchema,
  vehicleSchema,
  itemListSchemaNew,
}) => {
  const [showMenuItem, setShowMenuItem] = useState(false);

  const isWeb = isParkPlusWebView() || isWebView();

  const dispatch = useDispatch();
  const { profile, isAuthenticated } = useSelector((state: any) => state.auth);

  const { pathname } = useRouter();
  const isHomePage = pathname === '/';

  return (
    <div className={s.root}>
      {pageHeader?.show &&
        (isWeb ? (
          <div className={s.app_bar}>
            <AppBar title={{ label: title }} />
          </div>
        ) : (
          <div className={s.navbar_wrapper}>
            <div className={s.navbar}>
              <div className={cn('cmn_container', s.header)}>
                <Link href={'/'} className={s.logo}>
                  <ParkplusLogo />
                </Link>

                <div className={s.right}>
                  <div className={s.get_app_btn}>
                    <Button
                      variant="BlackNaked"
                      color="secondary"
                      label="Get the app"
                      size="Small"
                      leftIcon={<Download3 width={20} height={20} />}
                      onClick={redirectToAppStore}
                    />
                  </div>

                  <div className={cn(s.links, 'cmn_only_desktop')}>
                    <div>
                      <Link href={'/about-us'}>
                        <Typography label={'About us'} variant="body_big_m" />
                      </Link>
                    </div>
                    <div>
                      <Link href={'/business'}>
                        <Typography
                          label={'Park+ for Business'}
                          variant="body_big_m"
                        />
                      </Link>
                    </div>
                    {isAuthenticated ? (
                      <div
                        className={cn(s.profile_card, 'cmn_touchable_area')}
                        aria-hidden
                        onClick={() => {
                          redirectToPWA('/profile');
                        }}
                      >
                        <div className={s.user_img}>
                          <img
                            src={getUserProfilePic(profile)}
                            alt="User Image"
                          />
                        </div>
                        <div className={s.user_name}>
                          <Typography
                            label={getFullName(
                              profile.first_name,
                              profile.last_name
                            )}
                            variant="body_m"
                          />
                        </div>
                      </div>
                    ) : (
                      <Button
                        label="Sign in"
                        variant="BlackNaked"
                        size="Big"
                        onClick={() => {
                          dispatch(loginPopupUpdate({ show: true }));
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={cn(s.menu_btn, 'cmn_only_mobile')}
                    onClick={() => {
                      setShowMenuItem(!showMenuItem);
                    }}
                  >
                    {showMenuItem ? (
                      <Cross width={28} height={28} />
                    ) : (
                      <HamburgerMenu width={28} height={28} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!isHomePage && (
              <div
                className={cn(
                  'cmn_container',
                  'cmn_only_desktop',
                  s.header_links
                )}
              >
                {OurServices.map((item) => (
                  <Link
                    key={`${item?.title}_header_links`}
                    href={item.redirectionUrl}
                    target={item?.urlType === UrlType.External ?  '_blank' : '_self'}
                  >
                    
                    <Typography
                      label={item?.title}
                      variant="body_m"
                      color={
                        item?.redirectionUrl === pathname
                          ? 'interaction_button'
                          : 'text_caption'
                      }
                    />
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}

      {showMenuItem && (
        <NavbarModal
          show={showMenuItem}
          onClose={() => setShowMenuItem(false)}
        />
      )}

      <div
        className={cn(s.body, {
          [s.extra_margin]: !isHomePage,
          [s.no_margin]: !pageHeader?.show,
        })}
      >
        {children}
      </div>

      {!!mobileApplicationSchema?.show && <MobileApplicationSchema />}
      {!!siteNavigationSchema?.show && (
        <SiteNavigationElement data={siteNavigationSchema?.data} />
      )}
      {!!parkingSchema?.show && <ParkingFacility data={parkingSchema?.data} />}
      {!!tableSchema?.show && <TableSchema data={tableSchema?.data} />}
      {!!financialSchema?.show && (
        <FinancialProductSchema data={financialSchema?.data} />
      )}
      {!!newsArticle?.show && <NewsArticle data={newsArticle?.data} />}
      {!!blogArticle?.show && <BlogArticle data={blogArticle?.data} />}
      {!!authorSchema?.show && <AuthorSchema data={authorSchema?.data} />}
      {!!vehicleSchema?.show && <VehicleSchema data={vehicleSchema?.data} />}
      {!!itemListSchemaNew?.show && (
        <ItemListSchemaNew data={itemListSchemaNew?.data} />
      )}
      {breadCrumb?.show && <BreadCrumbV2 data={breadCrumb?.data} />}

      {footer.show && !isWeb && <Footer />}
    </div>
  );
};
