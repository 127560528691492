import { loginPopupUpdate } from 'auth/store/action';
import { vehicleNumberValidation } from 'core-lib/utils/validation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';
import { Button, Input, TabBar } from 'ui';
import s from './SearchWidget.module.scss';

const Tabs = {
  FASTag: 0,
  Challan: 1,
};

export const SearchWidget: React.FC = () => {
  const [activeTab, setActiveTab] = useState(Tabs.FASTag);
  const [vehicle, setVehicle] = useState({ input: '', error: '' });

  const dispatch = useDispatch();

  const isAuthenticated: boolean = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const validate = () => {
    const { isValid, errMsg } = vehicleNumberValidation(vehicle?.input);
    if (!isValid) {
      setVehicle((prev) => ({ ...prev, error: errMsg }));
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      let vrn = vehicle?.input;
      if (isAuthenticated) {
        redirectToPWA(
          activeTab === Tabs.FASTag
            ? `/services/fastag/recharge/validation`
            : `/services/challan/${vrn}`,
          activeTab === Tabs.FASTag
            ? {
                input: vrn,
                mode: 'license',
              }
            : {}
        );
      } else {
        dispatch(loginPopupUpdate({ show: true }));
      }
    }
  };

  return (
    <div className={s.root}>
      <div className={s.tabs}>
        <TabBar
          tabItems={Object.keys(Tabs)}
          activeTab={activeTab}
          onTabClick={(index) => setActiveTab(index)}
          variant="Default"
          alignCenter={false}
        />
      </div>
      <div className={s.form_wrapper}>
        <Input
          placeholder="Enter vehicle number"
          value={vehicle?.input}
          errorMessage={vehicle?.error}
          hasError={!!vehicle?.error}
          maxLength={12}
          onChange={(e) => {
            setVehicle((prev) => ({
              ...prev,
              //@ts-ignore
              input: e?.target?.value?.toUpperCase(),
            }));
          }}
        />
        <Button
          label="Submit"
          onClick={handleSubmit}
          isDisabled={vehicle?.input?.length < 10}
          fullwidth
        />
      </div>
    </div>
  );
};
