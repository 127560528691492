import Markdown from 'markdown-to-jsx';

import { Typography } from 'ui';
import { StrapiImageTypes } from '../../../../utils/types';
import s from './Hero.module.scss';
import SearchWidget from './SearchWidget';

import cn from 'classnames';
import { UrlType } from 'core-lib/types/Helper';
import Link from 'next/link';
import { OurServices } from 'src/common/constants/navbar';

type HeroDataType = {
  img: StrapiImageTypes;
  title: string;
  subtitle: string;
  qr_code: string;
  qr_text: string;
};

type HeroProps = {
  data: HeroDataType;
};

export const Hero: React.FunctionComponent<HeroProps> = ({ data }) => {
  return (
    <div className={s.root}>
      <div className={cn(s.top, 'cmn_container')}>
        <div className={s.text}>
          <Markdown className={s.title}>{data?.title}</Markdown>
          <Markdown className={s.subtitle}>{data?.subtitle}</Markdown>
        </div>
        <div className={s.widget}>
          <SearchWidget />
        </div>
      </div>
      <div className={cn(s.services_wrapper, 'cmn_container')}>
        <Typography
          label={'Do more with Park+'}
          className={s.title}
          variant="h2"
          renderAs="H2"
        />
        <div className={s.services}>
          {OurServices.map((item, idx) => (
            <div key={`${idx + 1}_services`} className={s.card_wrapper}>
              <Link
                href={item.redirectionUrl}
                className={cn(s.card, 'cmn_touchable_area')}
                target={item?.urlType === UrlType.External ?  '_blank' : '_self'}
              >
                <div className={s.icon}>
                  <img src={item?.icon} alt={item?.title} />
                </div>

                <Typography label={item?.title} variant="base_big_m" />
              </Link>
              {idx < OurServices.length - 1 && <div className={s.divider} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
