import dayjs from "dayjs";
import { OPEN_IN_BROWSER } from "../constants/nativeBridge";
import { PLATFORM } from "../data/common";
import { checkMobileOS } from "./helpers";
import { isValidMobileVersion, passMessageToNative } from "./nativeBridge";
import { isParkPlusWebView } from "./source";

export const isValid = (data) => data !== undefined && data !== null;

export function isStringValid(str): boolean {
  if (typeof str === "string") {
    const trimmed = str.trim();
    return !!trimmed;
  }
  return false;
}

export function isEmptyNullUndefined(data: string): boolean {
  if (data !== undefined && data !== null) {
    if (typeof data === "string" && data !== "") return false;
    if (typeof data === "object" && Object.keys(data).length) return false;
    if (typeof data === "boolean") return false;
  }
  return true;
}

export function validateAndTrimString(
  str: string,
  regex = /^[ A-Za-z0-9_@./#₹&+-,-]*$/
): {
  isValid: boolean;
  trimmedStr: string;
  errMsg: string;
} {
  let isValid = false;
  let trimmedStr = "";
  let errMsg = "Invalid";

  if (isStringValid(str)) {
    trimmedStr = str.trim();
    if (regex) {
      isValid = regex.test(trimmedStr);
    } else {
      isValid = true;
    }
    if (isValid) errMsg = "";
  } else {
    errMsg = "This field is required";
  }

  return { isValid, trimmedStr, errMsg };
}

export const validateDate = (date: string) => {
  return dayjs(new Date(date), "YYYY-MM-DD", true).isValid();
};

export const uuidGenerator = () =>
  //@ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const getRandomNumberBetweenMinAndMax = (
  min: number,
  max: number,
  roundoff = false
) => {
  let out = Math.random() * (max - min) + min;
  if (roundoff) {
    out = Math.round(out);
  }
  return out;
};

export const toTitleCase = (str) =>
  str?.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const toCapitalCase = (str: string) => {
  return str?.toUpperCase();
};

export const formatUrlWithQuery = (
  url: string,
  query: string,
  queryValue: string | number
) => {
  const urlLink = new URL(url);
  if (queryValue) {
    urlLink.searchParams.append(query, `${queryValue}`);
  }
  return urlLink.toString();
};

export const isValidJson = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

export const isServer = () => typeof window === "undefined";

export function generateRequestId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000000); // Adjust the range as needed

  return `req-${timestamp}-${random}`;
}

export function isSecureHeaderAvailable(): boolean {
  return (
    isValidMobileVersion({ platform: PLATFORM.android, version: "2.0.0" }) ||
    isValidMobileVersion({ platform: PLATFORM.ios })
  );
}
export function isNativeEventTrackingAvailable(): boolean {
  return (
    isValidMobileVersion({ platform: PLATFORM.android, version: "6.2.6" }) ||
    isValidMobileVersion({ platform: PLATFORM.ios, version: "3.2.97" })
  );
}

export function isNativeLoginAvailable(): boolean {
  return (
    isValidMobileVersion({ platform: PLATFORM.android, version: "6.2.12" }) ||
    isValidMobileVersion({ platform: PLATFORM.ios, version: "3.3.2" })
  );
}

export function isFingerprintAvailable(): boolean {
  return isValidMobileVersion({ platform: PLATFORM.android });
}

export function isNativeLoginOnTheGoEventAvailable(): boolean {
  if (isParkPlusWebView() && global?.JSChannel) {
    if (
      isValidMobileVersion({ platform: PLATFORM.android, version: "6.2.3" }) ||
      isValidMobileVersion({ platform: PLATFORM.ios, version: "3.2.95" })
    ) {
      return true;
    }
  }
  return false;
}

export function redirectToAppStore() {
  let url = "";
  const platform = checkMobileOS();
  if (platform.toLowerCase() === PLATFORM.android.toLowerCase()) {
    url =
      "https://play.google.com/store/apps/details?id=com.ovunque.parkwheels&hl=en_IN&gl=US";
  } else if (platform.toLowerCase() === PLATFORM.ios.toLowerCase()) {
    url =
      "https://itunes.apple.com/in/app/parkwheels-smart-parking-app/id1244749178?mt=8";
  } else {
    url =
      "https://play.google.com/store/apps/details?id=com.ovunque.parkwheels&hl=en_IN&gl=US";
  }
  if (isParkPlusWebView()) {
    passMessageToNative(OPEN_IN_BROWSER, url);
  } else {
    window.open(url, "_blank");
  }
}

export const toPascalCase = (s) => {
  if (s && typeof s === "string") {
    return s?.replace(
      /(\w)(\w*)/g,
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
    );
  }
  return s;
};

export const calculateProgressBar = (
  count: number,
  total: number,
  circumference: number
): number => {
  const width = circumference - (circumference * count) / total;
  return Math.round(width);
};

/**
 * A function to return number with ordinal, i.e. 1st, 2nd, 3rd, 4th, etc.
 *
 * @param n number or numerical string to converted to which ordinal suffix has to attached
 * @returns string: number with ordinal suffix
 * */
export function getOrdinalSuffix(n: number | string): string {
  if (isNaN(Number(n)) || Number(n) <= 0) return "-";
  const num = Number(n);
  return `${num}${
    num < 11 || num > 13
      ? ["st", "nd", "rd", "th"][Math.min((num - 1) % 10, 3)]
      : "th"
  }`;
}

export const checkPageRedirection = (code: number) => {
  return [301, 302, 307].includes(code);
};

export function sendToGoogleAnalytics(metric: any, appName: string) {
  const randomNumber = Math.random();

  //@ts-ignore
  window?.gtag &&
    metric?.value < 10000 &&
    randomNumber <= 0.1 &&
    //@ts-ignore
    window.gtag("event", `${appName}_${metric.name}`, {
      event_category: "Web Vitals",
      value: Math.round(
        metric.name === "CLS" ? metric.value * 1000 : metric.value
      ), // values must be integers
      event_label: metric.id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
}
