import { STRAPI_ENDPOINT, replaceImageKitUrl } from 'core-lib/data/common';
import { getMethod } from 'core-lib/utils/api';
import Head from 'next/head';
import qs from 'qs';
import NavbarLayout from 'src/common/components/NavbarLayout';
import ContactUsWrapper from 'src/components/common/contact_us_wrapper';
import MetaIndex from 'src/components/common/MetaIndex';
import BusinessProducts from 'src/modules/static/homePage/BusinessProducts';
import CaseStudies from 'src/modules/static/homePage/CaseStudies';
import GetTheApp from 'src/modules/static/homePage/GetTheApp';
import Hero from 'src/modules/static/homePage/Hero';
import OurPartners from 'src/modules/static/homePage/OurPartners';
import ParkplusForBusiness from 'src/modules/static/homePage/ParkplusForBusiness';
import ReasonToLove from 'src/modules/static/homePage/ReasonToLove';
import Responsibility from 'src/modules/static/homePage/Responsibility';
import Section from 'src/modules/static/homePage/Section';
import Section2 from 'src/modules/static/homePage/Section2';
import Stories from 'src/modules/static/homePage/Stories';
import StoriesV2 from 'src/modules/static/homePage/StoriesV2';
import UserRating from 'src/modules/static/homePage/UserRating';
import s from './index.module.scss';

type HomePageProps = {
  content: any;
  productsList: any[];
};

const HomePage = ({ content, productsList }: HomePageProps) => (
  <NavbarLayout
    footer={{ show: true }}
    header={{ show: true }}
    mobileApplicationSchema={{ show: true }}
    siteNavigationSchema={{
      show: true,
      data: { name: 'Home', url: '' },
    }}
    pageHeader={{
      show: true,
      backBtn: { show: false, onClick: () => {} },
      showProfile: true,
      showMenu: true,
    }}
  >
    <div className={s.container}>
      <Head>
        <title>{content?.seo?.meta_title}</title>
        <meta name="description" content={content?.seo?.meta_description} />
        <link rel="canonical" href={content?.seo?.canonical_url} />

        <meta property="og:image" content="/img/parkpluslogo.png" />
        <MetaIndex index={content?.seo?.should_index} />
        <meta
          name="facebook-domain-verification"
          content="ub1f062gwzual8erj4wak5uwmkse15"
        />
      </Head>
      <Hero data={content?.hero} />
      <BusinessProducts data={productsList} />
      <Section sectionContent={content?.section} />
      <Section2 sectionContent={content?.section} />
      <Responsibility data={content?.responsibilities} />
      <ReasonToLove content={content?.reason_to_love_parkplus} />
      <ParkplusForBusiness data={content?.parkplus_for_business} />
      <CaseStudies data={content?.case_studies} />
      <OurPartners data={content?.our_clients} />
      <Stories data={content?.stories} />
      <StoriesV2 data={content?.stories} />
      <UserRating data={content?.user_rating} />
      <GetTheApp data={content?.get_the_app} />
      <ContactUsWrapper />
    </div>
  </NavbarLayout>
);

export default HomePage;

export async function getStaticProps() {
  const query = qs.stringify(
    {
      populate: {
        hero: { populate: '*' },
        section: {
          populate: {
            items: { populate: '*' },
          },
        },
        responsibilities: { populate: '*' },
        case_studies: { populate: { cases: { populate: '*' } } },
        our_clients: { populate: { clients: { populate: { logo: '*' } } } },
        get_the_app: {
          populate: {
            download_app: {
              populate: {
                playstore_img: '*',
                app_store_img: '*',
              },
            },
            img: '*',
          },
        },
        parkplus_for_business: {
          populate: {
            corporate: { populate: { images: { populate: '*' } } },
            society: { populate: { images: { populate: '*' } } },
            parking_owners: { populate: { images: { populate: '*' } } },
            malls: { populate: { images: { populate: '*' } } },
          },
        },
        user_rating: { populate: { ratings: { populate: { user_img: '*' } } } },
        stories: {
          populate: {
            quotes_img: { populate: '*' },
            stories_list: { populate: '*' },
          },
        },
        seo: { populate: '*' },
        reason_to_love_parkplus: {
          populate: { reasons: { populate: { img: { populate: '*' } } } },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  const { response }: any = await getMethod(
    `${STRAPI_ENDPOINT}/api/parkplus-home-page?${query}`
  );

  const content = replaceImageKitUrl(response);

  const stickyBanner = content?.stickyBanner || null;

  const hardwarePagesQuery = qs.stringify({
    populate: {
      heroTitle: {
        populate: '*',
      },
      heroSubtitle: {
        populate: '*',
      },
      productImages: {
        populate: '*',
      },
      variants: {
        populate: '*',
      },
      specifications: {
        populate: '*',
      },
      section: {
        populate: {
          image: {
            populate: '*',
          },
          cards: {
            populate: '*',
          },
        },
      },
      conclusion: {
        populate: {
          section: {
            populate: '*',
          },
        },
      },
    },
  });

  const { response: productsList }: any = await getMethod(
    `${STRAPI_ENDPOINT}/api/business-hardware-pages?filters[category]=root&sort=createdAt:desc&pagination[limit]=10&${hardwarePagesQuery}`
  );

  return {
    props: {
      content: content?.data?.attributes || null,
      stickyBanner,
      productsList: productsList?.data || null,
    },
  };
}
