/* eslint-disable import/no-cycle */
/* eslint-disable max-depth */
import {
  CALL_EVENT,
  CHECK_NATIVE_PERMISSION,
  CONTACT,
  DOWNLOAD_FILE_EVENT,
  ENABLE_NOTIFICATION,
  ENABLE_VACCINE_NOTIFICATION,
  FETCH_NATIVE_CONTACT_LIST,
  FETCH_NATIVE_LOCATION,
  FIRE_REFRESH_HOME,
  GET_SECURE_KEY,
  GET_SHARED_PREFERENCE,
  HANDLE_HEADER,
  HAPTIC_FEEDBACK,
  HIDE_BACK_BUTTON,
  LOCATION_EVENT,
  LOCK_TAG,
  MAKE_PAYMENT,
  MAKE_UPI_PAYMENT,
  NATIVE_LOGIN,
  NATIVE_MEDIA_UPLOAD,
  NATIVE_RAZORPAY_CALLBACK,
  OPEN_FEATURE,
  OPEN_FIT_KIT,
  OPEN_IN_BROWSER,
  OPEN_QR_CODE,
  OPEN_SEPERATE_WEB_VIEW,
  OPEN_SHORTS,
  OPEN_WEB_VIEW_EVENT,
  OPEN_YOUTUBE_PLAYER,
  PAGE_LOAD_SIGNAL,
  PAYTM_ADD_MONEY_EVENT,
  PWA_TO_NATIVE_AUTH,
  REFRESH_NATIVE_SCREEN,
  REPLACE_TAG,
  REVERSE_GEO_CODING,
  SCRIPT_EVENT,
  SET_SHARED_PREFERENCE,
  SET_WALLPAPER,
  SHARE_EVENT,
  SHARE_WITHOUT_SCEREENSHOT,
  SHOW_BACK_BUTTON,
  SHOW_IN_APP_REVIEW,
  TRACK_EVENT,
  TRACK_NATIVE_APP_EVENT,
  UPDATE_STATUS_BAR,
  SCANNER,
  PERMISSION_EVENT,
  SHARED_PREFERENCE,
  APP_BAR,
} from "core-lib/constants/nativeBridge";
import { Cookies, SessionStorage } from "core-lib/constants/storage";
import { PLATFORM } from "core-lib/data/common";
import {
  checkMobileOS,
  getPlatform,
  isDataValid,
  isObject,
  isWebView,
} from "core-lib/utils/helpers";
import { isNumeric } from "core-lib/utils/regex";
import {
  isMoaWebView,
  isParkPlusWebView,
  isPmsWebView,
  isPndWebView,
  isTOAWebView,
  isUCDWebView,
} from "core-lib/utils/source";
import { getCookieValue } from "./cookie";

export const passMessageToNative = (
  message: string,
  title: any = null,
  content: any = null
) => {
  if (isWebView()) {
    const platform = checkMobileOS();
    let contentStr = isObject(content) ? JSON.stringify(content) : content;
    if (isUCDWebView()) {
      // @ts-ignore
      const RNWebView = global?.ReactNativeWebView;
      if (RNWebView) {
        contentStr = {
          event: message,
          data: content,
          os: platform,
        };
        RNWebView.postMessage(JSON.stringify(contentStr));
      }
    } else if (
      isTOAWebView() ||
      isPndWebView() ||
      isMoaWebView() ||
      isPmsWebView()
    ) {
      // @ts-ignore
      const JS_BRIDGE = global?.JSBridge;
      if (JS_BRIDGE?.fireJavaScriptEvent) {
        JS_BRIDGE.fireJavaScriptEvent(message, title, contentStr);
      }
    } else if (isParkPlusWebView()) {
      // For Flutter WebView to Native(ios) WebView handling
      let eventName = message;

      if (platform === PLATFORM.android) {
        // @ts-ignore
        const JS_BRIDGE = global?.JSBridge;

        if (JS_BRIDGE?.fireJavaScriptEvent) {
          JS_BRIDGE.fireJavaScriptEvent(message, title, contentStr);
        }
      } else if (platform === PLATFORM.ios) {
        // @ts-ignore
        const WEBKIT = global?.webkit;
        if (WEBKIT?.messageHandlers) {
          switch (message) {
            case SHARE_EVENT:
              eventName = "fireShareEvent";
              if (WEBKIT.messageHandlers.fireShareEvent)
                WEBKIT.messageHandlers.fireShareEvent.postMessage(contentStr);
              break;
            case SHARE_WITHOUT_SCEREENSHOT:
              eventName = "fireOpenWhatsapp";
              if (WEBKIT.messageHandlers.fireOpenWhatsapp)
                WEBKIT.messageHandlers.fireOpenWhatsapp.postMessage(contentStr);
              break;
            case CALL_EVENT:
              eventName = "fireCallEvent";
              if (WEBKIT.messageHandlers.fireCallEvent)
                WEBKIT.messageHandlers.fireCallEvent.postMessage(contentStr);
              break;
            case PAYTM_ADD_MONEY_EVENT:
              eventName = "firePaytmAddMoneyEvent";
              if (WEBKIT.messageHandlers.firePaytmAddMoneyEvent)
                WEBKIT.messageHandlers.firePaytmAddMoneyEvent.postMessage(
                  title
                );
              break;
            case OPEN_QR_CODE:
              if (WEBKIT.messageHandlers.fireQRCodeEvent)
                WEBKIT.messageHandlers.fireQRCodeEvent.postMessage(contentStr);
              break;
            case DOWNLOAD_FILE_EVENT:
              eventName = "fireDownloadEvent";
              if (WEBKIT.messageHandlers.fireDownloadEvent)
                WEBKIT.messageHandlers.fireDownloadEvent.postMessage(
                  contentStr
                );
              break;
            case OPEN_WEB_VIEW_EVENT:
              eventName = "fireOpenWebViewEvent";
              if (WEBKIT.messageHandlers.fireOpenWebViewEvent)
                WEBKIT.messageHandlers.fireOpenWebViewEvent.postMessage(
                  `${contentStr}|||${title}`
                );
              break;
            case HANDLE_HEADER:
              eventName = "fireHandlerHeaderEvent";
              if (WEBKIT.messageHandlers.fireHandlerHeaderEvent)
                WEBKIT.messageHandlers.fireHandlerHeaderEvent.postMessage(
                  contentStr
                );
              break;
            case SHOW_BACK_BUTTON:
              eventName = "fireShowBackButtonEvent";
              if (WEBKIT.messageHandlers.fireShowBackButtonEvent)
                WEBKIT.messageHandlers.fireShowBackButtonEvent.postMessage(
                  contentStr
                );
              break;
            case HIDE_BACK_BUTTON:
              eventName = "fireHideBackButtonEvent";
              if (WEBKIT.messageHandlers.fireHideBackButtonEvent)
                WEBKIT.messageHandlers.fireHideBackButtonEvent.postMessage(
                  contentStr
                );
              break;
            case SET_WALLPAPER:
              eventName = "fireHideBackButtonEvent";
              if (WEBKIT.messageHandlers.fireHideBackButtonEvent)
                WEBKIT.messageHandlers.fireSetWallPaper.postMessage(contentStr);
              break;
            case TRACK_NATIVE_APP_EVENT:
              if (isDataValid(WEBKIT.messageHandlers.fireAnalyticEvent)) {
                WEBKIT.messageHandlers.fireAnalyticEvent.postMessage({
                  event: title,
                  attr: content,
                });
              }
              break;
            case ENABLE_NOTIFICATION:
              eventName = "fireEnableNotificationEvent";
              if (WEBKIT.messageHandlers.fireEnableNotificationEvent)
                WEBKIT.messageHandlers.fireEnableNotificationEvent.postMessage(
                  ""
                );
              break;
            case NATIVE_RAZORPAY_CALLBACK:
              eventName = "fireNativeRazorpayCallbackEvent";
              if (WEBKIT.messageHandlers.fireNativeRazorpayCallbackEvent)
                WEBKIT.messageHandlers.fireNativeRazorpayCallbackEvent.postMessage(
                  ""
                );
              break;
            case ENABLE_VACCINE_NOTIFICATION:
              eventName = "fireEnableVaccineNotifications";
              if (WEBKIT.messageHandlers.fireEnableVaccineNotifications)
                WEBKIT.messageHandlers.fireEnableVaccineNotifications.postMessage(
                  ""
                );
              break;
            case OPEN_IN_BROWSER:
              if (WEBKIT.messageHandlers.fireOpenInBrowser)
                WEBKIT.messageHandlers.fireOpenInBrowser.postMessage(title);
              break;
            case OPEN_YOUTUBE_PLAYER:
              eventName = "fireOpenYoutubePlayer";
              if (WEBKIT.messageHandlers.fireOpenYoutubePlayer)
                WEBKIT.messageHandlers.fireOpenYoutubePlayer.postMessage(
                  contentStr
                );
              break;
            case MAKE_UPI_PAYMENT:
              eventName = "fireMakeUPIPayment";
              if (WEBKIT.messageHandlers.fireMakeUPIPayment)
                WEBKIT.messageHandlers.fireMakeUPIPayment.postMessage(
                  contentStr
                );
              break;
            case OPEN_FEATURE:
              if (WEBKIT.messageHandlers.fireOpenFeature)
                WEBKIT.messageHandlers.fireOpenFeature.postMessage(contentStr);
              break;
            case SCRIPT_EVENT:
              if (WEBKIT.messageHandlers.fireScriptEvent)
                WEBKIT.messageHandlers.fireScriptEvent.postMessage(contentStr);
              break;
            case OPEN_SEPERATE_WEB_VIEW:
              eventName = "openSeparateWebView";
              if (WEBKIT.messageHandlers.fireOpenWebViewEvent) {
                WEBKIT.messageHandlers.fireOpenWebViewEvent.postMessage(
                  contentStr + "|||"
                );
              }
              break;
            case FIRE_REFRESH_HOME:
              eventName = "fireRefreshHome";
              if (WEBKIT.messageHandlers.fireRefreshHome) {
                WEBKIT.messageHandlers.fireRefreshHome.postMessage(message);
              }
              break;
            case PAGE_LOAD_SIGNAL:
            case UPDATE_STATUS_BAR:
            case REPLACE_TAG:
            case LOCK_TAG:
            case FETCH_NATIVE_LOCATION:
            case OPEN_FIT_KIT:
            case MAKE_PAYMENT:
            case OPEN_SHORTS:
            case REFRESH_NATIVE_SCREEN:
            case PWA_TO_NATIVE_AUTH:
            case NATIVE_MEDIA_UPLOAD:
            case SHOW_IN_APP_REVIEW:
            case GET_SHARED_PREFERENCE:
            case SET_SHARED_PREFERENCE:
            case REVERSE_GEO_CODING:
            case FETCH_NATIVE_CONTACT_LIST:
            case CHECK_NATIVE_PERMISSION:
            case HAPTIC_FEEDBACK:
            case GET_SECURE_KEY:
            case CONTACT:
            case LOCATION_EVENT:
            case TRACK_EVENT:
            case NATIVE_LOGIN:
            case SCANNER:
            case PERMISSION_EVENT:
            case APP_BAR:
            case SHARED_PREFERENCE:
              contentStr = {
                event: message,
                data: content,
              };

              if (WEBKIT.messageHandlers.fireCommonEvent)
                WEBKIT?.messageHandlers?.fireCommonEvent?.postMessage(
                  JSON.stringify(contentStr)
                );
              break;

            default:
              if (
                message !== FIRE_REFRESH_HOME &&
                WEBKIT.messageHandlers.fireJavaScriptEvent
              )
                WEBKIT.messageHandlers.fireJavaScriptEvent.postMessage(message);
          }
        }
      }

      // For new webview in Flutter
      if (global?.JSChannel) {
        let contentN = content;
        if (message == TRACK_NATIVE_APP_EVENT) {
          contentN = JSON.stringify({
            event_name: title,
            event_attr: content,
          });
        } else if (message == OPEN_IN_BROWSER) {
          contentN = {
            web_url: title,
          };
        } else if (message === SHARE_EVENT) {
          eventName = "fireOpenWhatsapp";
        }

        global.JSChannel.postMessage(
          JSON.stringify({
            event: eventName,
            data: contentN,
          })
        );
      }
    }
  }
};

const isEveryItemNumber = (list: any[]) =>
  list?.every((item) => isNumeric(item));

export const shouldPassNoScreenshotEvent = () => {
  return isValidMobileVersion({ platform: PLATFORM.android });
};

const minimumRequiredVersion = (version: string) => {
  const minimumRequiredVersion = version?.split(".");
  let appVersion = getCookieValue(Cookies.VersionName);

  if (!appVersion) {
    return false;
  }
  // @ts-ignore
  appVersion = appVersion?.split(".");

  // @ts-ignore
  const isEveryAppVersionItemNumber = isEveryItemNumber(appVersion);
  const isEveryMinRequiredVersionItemNumber = isEveryItemNumber(
    minimumRequiredVersion
  );

  if (isEveryMinRequiredVersionItemNumber && isEveryAppVersionItemNumber) {
    if (Number(minimumRequiredVersion[0]) < Number(appVersion[0])) {
      return true;
    } else if (Number(minimumRequiredVersion[0]) === Number(appVersion[0])) {
      if (Number(minimumRequiredVersion[1]) < Number(appVersion[1])) {
        return true;
      } else if (Number(minimumRequiredVersion[1]) === Number(appVersion[1])) {
        if (Number(minimumRequiredVersion[2]) <= Number(appVersion[2])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isValidMobileVersion = ({
  version = null,
  platform,
}: {
  version?: string;
  platform: string;
}) => {
  let isValid = false;
  if (isParkPlusWebView()) {
    if (getPlatform().toLowerCase() === platform.toLowerCase()) {
      if (version !== null) return minimumRequiredVersion(version);
      isValid = true;
    }
  }
  return isValid;
};

export const getAppRating = () => {
  //session storage was added to avoid asking user repeatedly in a session for app rating.
  const eventSent = sessionStorage.getItem(SessionStorage.AppRatingEventSent);
  if (eventSent !== "true") {
    passMessageToNative(SHOW_IN_APP_REVIEW);
    sessionStorage.setItem(SessionStorage.AppRatingEventSent, "true");
  }
};

export function waitForSecureKeyUpdate(key) {
  return new Promise((resolve) => {
    function storageChangeListener() {
      const value = sessionStorage.getItem(key);
      if (value !== null) {
        resolve(value);
        sessionStorage.removeItem(key);
        window.removeEventListener("SecureKeyStorage", storageChangeListener);
      }
    }

    window.addEventListener("SecureKeyStorage", storageChangeListener);
  });
}

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export function waitForCustomEvent(
  eventName: string,
  target = window,
  enableFallback = false
) {
  return new Promise((resolve) => {
    const eventListener = (event) => {
      if (event instanceof CustomEvent && event.type === eventName) {
        resolve(event);
        target.removeEventListener(eventName, eventListener);
      }
    };
    target.addEventListener(eventName, eventListener);

    if (enableFallback) {
      setTimeout(() => {
        resolve(null);
        target.removeEventListener(eventName, eventListener);
      }, 1000);
    }
  });
}
