import axios from "axios";
import { ERROR_MESSAGE } from "core-lib/constants/network";
import { checkMobileOS, isDataValid } from "core-lib/utils/helpers";
import { API_SECRET } from "../config/Env";
import errorHandler from "./error_handler";
import { genericErrorHandler } from "./genericErrorHandler";
import { addToCacheQueue, deleteDataFromCache, getCachedData } from "./idb";
import { getDeviceId, getNativeVersions, isParkPlusWebView } from "./source";
import { isServer } from "./utils";

export enum CacheStrategyEnum {
  NoStore="NoStore", 
  Cache="Cache",
  CacheAndRefresh="CacheAndRefresh",
}

type ApiTypes = {
  response?: { [key: string]: any };
  header?: { [key: string]: any };
  error?: string | { [key: string]: any };
  status?: number;
  toast?: any;
  timeout?: number;
  errorData?: { [key: string]: any };
};

export async function getMethod({
  endPoint,
  headers = { "Cache-Control": "no-cache" },
  params = null,
  enableErrorHandling = false,
  timeout = 50000,
  cacheStrategy = CacheStrategyEnum.NoStore,
  maxCacheAge = 24, // in hours
}: {
  endPoint: string;
  headers?: any;
  params?: { [key: string]: any };
  enableErrorHandling?: boolean;
  timeout?: number;
  cacheStrategy?: keyof typeof CacheStrategyEnum;
  maxCacheAge?: number;
}): Promise<ApiTypes> {
  const maxAge = maxCacheAge * 3600 * 1000;
  const url = new URL(endPoint);
  if (params) {
    for (let k in params) {
      url.searchParams.append(k, params[k]);
    }
  }

  const cacheKey = url.toString();

  // Check for cached data
  if (cacheStrategy !== CacheStrategyEnum.NoStore) {
    const cache = await getCachedData(cacheKey);
    if (cache) {
      const isExpired =
        new Date().getTime() - new Date(cache.timestamp).getTime() >
        maxAge;

      if (!isExpired) {
        // Trigger a background fetch without waiting for it
        if (cacheStrategy === CacheStrategyEnum.CacheAndRefresh) {
          fetchAndUpdateCache({
            endPoint,
            headers,
            params,
            timeout,
            cacheKey,
            cacheStrategy,
            enableErrorHandling,
          });
        }

        return cache?.data; // Immediately return cached data
      }

      // If expired, delete the cache entry
      await deleteDataFromCache(cacheKey);
    }
  }

  // Fetch fresh data directly if no valid cache is available
  return fetchAndUpdateCache({
    endPoint,
    headers,
    params,
    timeout,
    cacheKey,
    cacheStrategy,
    enableErrorHandling,
  });
}

// Function to fetch fresh data and update cache
async function fetchAndUpdateCache({
  endPoint,
  headers,
  params,
  timeout,
  cacheKey,
  cacheStrategy,
  enableErrorHandling,
}: {
  endPoint: string;
  headers: any;
  params: any;
  timeout: number;
  cacheKey: string;
  cacheStrategy: `${CacheStrategyEnum}`;
  enableErrorHandling?: boolean;
}): Promise<ApiTypes> {
  try {
    const response = await axios.get(`${endPoint}`, {
      headers,
      params,
      timeout,
    });
    const obj = {
      response: response?.data,
      header: response?.headers,
      toast: errorHandler(response),
      status: response?.status,
    }
    if (cacheStrategy !== CacheStrategyEnum.NoStore) {
      // Add to the queue for background processing
      addToCacheQueue(cacheKey, {
        data: obj,
        timestamp: new Date().toISOString(),
      });
    }

    return obj;
  } catch (error) {
    if (enableErrorHandling) {
      genericErrorHandler(error?.response);
    }
    return {
      error: error?.response?.data ?? ERROR_MESSAGE.genericFailure,
      header: error?.response?.headers,
      toast: errorHandler(error?.response),
      errorData: error?.response,
      status: error?.response?.status,
    };
  }
}

export function postMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000,
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: Headers | any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";
  if (isDataValid(headers)) {
    if (headers?.has && headers.has("Content-Type")) {
      headerData = { headers };
    } else {
      headerData = {
        headers: { ...headers, "Content-Type": "application/json" },
      };
    }
  }

  return axios
    .post(`${endPoint}`, body, { ...headerData, timeout })
    .then((response) => ({
      response: response?.data,
      status: response?.status,
    }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
        status: error?.response?.status,
      };
    });
}

export function putMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000,
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";

  if (isDataValid(headers)) {
    headerData = {
      headers: { ...headers, "Content-Type": "application/json" },
    };
  }

  return axios
    .put(`${endPoint}`, body, { ...headerData, timeout })
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}

export function patchMethod({
  endPoint,
  body,
  headers = null,
  enableErrorHandling = false,
  timeout = 50000,
}: {
  endPoint: string;
  body: { [key: string]: any } | null;
  headers?: any;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let headerData: any = null;
  let errorMessage = "";

  if (isDataValid(headers)) {
    headerData = { headers };
  }

  return axios
    .patch(`${endPoint}`, body, { ...headerData, timeout })
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}

export function deleteMethod({
  endPoint,
  body = null,
  headers = { "Content-Type": "application/json" },
  enableErrorHandling = false,
  timeout = 50000, // Default timeout of 50 seconds
}: {
  endPoint: string;
  body?: { [key: string]: any } | null;
  headers?: { [key: string]: any } | null;
  enableErrorHandling?: boolean;
  timeout?: number;
}): Promise<ApiTypes> {
  let errorMessage = "";
  let headerData: any = null;

  if (isDataValid(headers)) {
    headerData = { ...headers };
  }

  const data = { ...body };

  return axios
    .delete(`${endPoint}`, { data, headers: headerData, timeout })
    .then((response) => ({ response: response.data, status: response.status }))
    .catch((error) => {
      if (enableErrorHandling) {
        genericErrorHandler(error?.response);
      }
      if (
        typeof error?.response?.data === "string" &&
        error?.response?.data === ""
      ) {
        errorMessage = ERROR_MESSAGE.genericFailure;
      } else {
        errorMessage = error?.response?.data;
      }
      return {
        error: errorMessage ?? ERROR_MESSAGE.genericFailure,
      };
    });
}

export function setAuthHeaders(token) {
  token && (axios.defaults.headers.common.Authorization = token);
}

export function removeAuthHeaders() {
  delete axios.defaults.headers.common["Authorization"];
}

export function setClientHeaders(
  options = null,
  clientId = API_SECRET.clientId,
  clientSecret = API_SECRET.clientSecret
) {
  axios.defaults.headers.common["client-id"] = clientId;
  axios.defaults.headers.common["client-secret"] = clientSecret;
  axios.defaults.headers.common.platform = "web";
  axios.defaults.headers.common["package-name"] = "web.pwa";
  if (!isServer()) {
    const isPPWebview = isParkPlusWebView();
    axios.defaults.headers.common["app-name"] = isPPWebview
      ? "B2C"
      : "Park+ PWA";
    if (isPPWebview) {
      axios.defaults.headers.common["native-platform"] = checkMobileOS();
    }
  }

  const { appVersion, osVersion } = getNativeVersions(options);
  if (appVersion) {
    axios.defaults.headers.common["version-name"] = appVersion;
  }
  if (osVersion) {
    axios.defaults.headers.common["os-version"] = osVersion;
  }
  const deviceId = getDeviceId(options);
  if (deviceId) {
    axios.defaults.headers.common["device-id"] = deviceId;
  }
  axios.defaults.timeout = 50000;
}
