import cn from "classnames";
import { Color, ColorType, TypeScales } from "../../primitives";
import Typography from "../Typography";
import s from "./TabBar.module.scss";

type TabBarVariantType = "Default" | "WhiteText";

export interface TabBarProps {
  tabItems: string[];
  activeTab: number;
  onTabClick: (e: number) => void;
  variant?: TabBarVariantType;
  badgeItems?: string[];
  parentCustomStyle?: any;
  alignCenter?: boolean;
}

export function TabBar(props: TabBarProps) {
  const {
    tabItems,
    activeTab,
    onTabClick,
    variant = "Default",
    badgeItems = [],
    parentCustomStyle = {},
    alignCenter = true
  } = props;
  let activeTextColor: ColorType = "text_default";
  let inactiveTextColor: ColorType = "grey_40";
  switch (variant) {
    case "WhiteText":
      activeTextColor = "white";
      inactiveTextColor = "text_label";
      break;
    case "Default":
      activeTextColor = "text_default";
      inactiveTextColor = "text_label";
  }
  //this will bring the element to center
  const handlePosition = (event) => {
    event.target.scrollIntoView({ inline: "center", block: "nearest" });
  };

  return (
    <div className={s.header} id="tabBarContainer" style={parentCustomStyle}>
      {tabItems?.map((e, i) => (
        <button
          type="button"
          onClick={(event) => {
            onTabClick(i);
            if(alignCenter){
              handlePosition(event);
            }
          }}
          className={cn([activeTab === i ? s.active : s.unactive, s[variant]])}
          key={`${e},${i}`}
        >
          <Typography
            label={e}
            variant={activeTab === i ? TypeScales.body_m : TypeScales.body_r}
            color={activeTab === i ? activeTextColor : inactiveTextColor}
          />
          {badgeItems?.length ? (
            <div
              className={s.badge}
              style={{
                background:
                  activeTab === i
                    ? `var(--${Color.positive_text})`
                    : `var(--${Color.grey_60})`
              }}
            >
              <Typography
                variant="tiny_m"
                color="background_primary"
                label={badgeItems[i]}
              />
            </div>
          ) : null}
        </button>
      ))}
    </div>
  );
}
