import { loginPopupUpdate } from 'auth/store/action';
import cn from 'classnames';
import { UrlType } from 'core-lib/types/Helper';
import { getFullName, getUserProfilePic } from 'core-lib/utils/helpers';
import Link from 'next/link';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BusinessProductsData, OurServices } from 'src/common/constants/navbar';
import BusinessProducts from 'src/modules/static/homePage/BusinessProducts';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';
import { Modal, Typography } from 'ui';
import ChevronRight from 'ui/components/newIcons/ChevronRight';
import RoundDownArrow1 from 'ui/components/newIcons/RoundDownArrow1';
import RoundUpArrow1 from 'ui/components/newIcons/RoundUpArrow1';
import UserProfile from 'ui/components/newIcons/UserProfile';
import s from './NavbarModal.module.scss';

type NavbarModalProps = {
  show: boolean;
  onClose: () => void;
};

export const NavbarModal: React.FunctionComponent<NavbarModalProps> = ({
  show,
  onClose,
}) => {
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const { isAuthenticated, profile } = useSelector((state: any) => state.auth);

  return (
    <Modal
      show={show}
      head={{ show: false, onClose: onClose }}
      variant="page"
      animation={{ type: 'rtl', timeout: 'm' }}
      rootStyle={{ zIndex: 5 }}
    >
      <div className={s.root}>
        <div
          className={cn(s.profile, 'cmn_touchable_area')}
          aria-hidden
          onClick={() => {
            if (isAuthenticated) {
              redirectToPWA('/profile');
            } else {
              dispatch(loginPopupUpdate({ show: true }));
            }
          }}
        >
          <div className={s.profile_img}>
            {isAuthenticated ? (
              <img src={getUserProfilePic(profile)} alt="User Image" />
            ) : (
              <UserProfile primaryColor="grey_80" width={46} height={46} />
            )}
          </div>
          <div className={s.right}>
            <div className={s.text}>
              <Typography
                label={
                  isAuthenticated
                    ? getFullName(profile.first_name, profile.last_name)
                    : 'Login / Signup'
                }
                variant="body_big_m"
                color={isAuthenticated ? 'text_default' : 'interaction_button'}
              />
              <Typography
                label={
                  isAuthenticated
                    ? profile?.phone_number
                    : 'Login to avail best deals'
                }
                variant="body_m"
                color="text_caption"
              />
            </div>

            <ChevronRight primaryColor="text_caption" />
          </div>
        </div>
        <div className={s.services_wrapper}>
          <div
            className={cn(s.services, {
              [s.show_more]: showMore,
            })}
          >
            {OurServices.map((item, idx) => (
              <div key={`${idx + 1}_services`} className={s.card_wrapper}>
                <Link
                  href={item.redirectionUrl}
                  className={cn(s.card, 'cmn_touchable_area')}
                  target={
                    item?.urlType === UrlType.External ? '_blank' : '_self'
                  }
                >
                  <div className={s.icon}>
                    <img src={item?.icon} alt={item?.title} />
                  </div>

                  <Typography label={item?.title} variant="base_big_m" />
                </Link>
                {idx < OurServices.length - 1 && <div className={s.divider} />}
              </div>
            ))}
          </div>
          {OurServices?.length > 8 && (
            <div
              className={cn(s.arrow, 'cmn_touchable_area')}
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? (
                <RoundUpArrow1
                  primaryColor="interaction_buttons"
                  height={32}
                  width={32}
                />
              ) : (
                <RoundDownArrow1
                  primaryColor="interaction_buttons"
                  height={32}
                  width={32}
                />
              )}
            </div>
          )}
        </div>
        <div className={s.business}>
          <BusinessProducts data={BusinessProductsData} />
        </div>
      </div>
    </Modal>
  );
};
