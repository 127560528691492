import React from "react";
import { IconTypes } from "ui/primitives/IconTypes";
import { getThemeColor, Color } from "ui/primitives";

export default ({
                  width = 24,
                  height = 24,
                  primaryColor = Color.text_default,
                  variant
                }: IconTypes) => {
  const primaryColorValue = `var(--${primaryColor})`;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75ZM8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
        fill={primaryColorValue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76863 16.9363C7.11011 17.6192 6.8578 18.6297 6.78011 20.0412C6.75735 20.4548 6.40362 20.7716 5.99003 20.7489C5.57644 20.7261 5.25962 20.3724 5.28238 19.9588C5.36384 18.4788 5.63375 16.9893 6.68885 15.8951C7.75301 14.7915 9.45465 14.25 12.0004 14.25C14.5462 14.25 16.2479 14.7915 17.312 15.8951C18.3671 16.9893 18.637 18.4788 18.7185 19.9588C18.7413 20.3724 18.4244 20.7261 18.0108 20.7489C17.5973 20.7716 17.2435 20.4548 17.2208 20.0412C17.1431 18.6297 16.8908 17.6192 16.2322 16.9363C15.5828 16.2628 14.3794 15.75 12.0004 15.75C9.62148 15.75 8.4181 16.2628 7.76863 16.9363Z"
        fill={primaryColorValue}
      />
    </svg>
  );
};
