/** @format */

import classnames from "classnames";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import s from "./AnimatedImage.module.scss";

interface OwnProps {
  src: string;
  alt?: string;
  loading?: "lazy" | "eager";
  className?: string;
  customStyle?: any;
  enablePinchToZoom?: boolean;
  onLoad?: () => void;
}

interface OwnProps {
  src: string;
  alt?: string;
  className?: string;
  customStyle?: any;
}

type Props = OwnProps;

export const AnimatedImage: FunctionComponent<Props> = ({
  src,
  alt,
  className,
  loading = "lazy",
  customStyle = {},
  enablePinchToZoom = false,
  onLoad,
}) => {
  // state
  const [isVisible, setIsVisible] = useState(false);

  const imgRef = useRef(null);

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      // @ts-ignore
      img.style.setProperty("transform", value);
    }
  }, []);

  useEffect(() => {
    if (imgRef?.current?.complete) {
      imgRef.current.style.visibility = "visible";
      setIsVisible(true);
      onLoad && onLoad();
    }
  }, []);

  return enablePinchToZoom ? (
    <QuickPinchZoom onUpdate={onUpdate} doubleTapToggleZoom tapZoomFactor={2}>
      <img
        onLoad={(e) => {
          e.currentTarget.style.visibility = "visible";
          setIsVisible(true);
          if (onLoad) {
            onLoad();
          }
        }}
        className={classnames(className, {
          [s.hide]: !isVisible,
          [s.show]: isVisible,
        })}
        src={src}
        alt={alt}
        style={customStyle}
        loading={loading}
        ref={imgRef}
      />
    </QuickPinchZoom>
  ) : (
    <img
      onLoad={(e) => {
        e.currentTarget.style.visibility = "visible";
        setIsVisible(true);
        if (onLoad) {
          onLoad();
        }
      }}
      className={classnames(className, {
        [s.hide]: !isVisible,
        [s.show]: isVisible,
      })}
      src={src}
      alt={alt}
      style={customStyle}
      loading={loading}
      ref={imgRef}
    />
  );
};
