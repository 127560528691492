import { useState } from 'react';

const useForm = (callback, validate, initState) => {
  const [form, setForm] = useState(initState);
  const [submitClick, setSubmitClick] = useState(false);

  // useEffect(() => {
  //   if (Object.keys(form).every(key => form[key].err === '') && isSubmitting) {
  //     callback();
  //   }
  // }, [form]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const validatedForm = validate(form);
    setForm(validatedForm);
    setSubmitClick(true);
    if (
      Object.keys(validatedForm).every((key) => validatedForm[key].err === '')
    ) {
      callback(validatedForm);
      setForm(initState);
    }
  };

  const handleChange = (name, value, target) => {
    const formData = { ...form };
    formData[name].input = value;
    setForm(formData);
    if (submitClick) setForm(validate(form), name);
  };

  return {
    handleChange,
    handleSubmit,
    form,
    setForm
  };
};

export default useForm;
