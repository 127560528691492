import cn from 'classnames';
import Link from 'next/link';
import React from 'react';
import { Button, Typography } from 'ui';
import ChevronRight from 'ui/components/newIcons/ChevronRight';
import s from './BusinessProducts.module.scss';

type BusinessProducts = {
  data: any[];
};

export const BusinessProducts: React.FunctionComponent<BusinessProducts> = ({
  data,
}) => {
  return (
    <div className={cn('cmn_container', s.root)}>
      <Typography label={'Park+ for Businesses'} variant="h2" renderAs="H2" />
      <div className={cn(s.cards, 'cmn_hide_scrollbar')}>
        {data.map((product) => (
          <Link
            href={`/business-solution/${product?.attributes?.slug}`}
            className={s.card}
            key={`${product?.attributes?.slug}_business_products`}
          >
            <div className={s.cardImg}>
              <img
                src={
                  product?.attributes?.productImages?.data?.[0]?.attributes?.url
                }
                alt={
                  product?.attributes?.productImages?.data?.[0]?.alternativeText
                }
              />
            </div>
            <div className={s.cardContent}>
              <Typography
                className={cn('cmn_one_line_clamp')}
                label={product?.attributes?.heroTitle}
                variant="h4"
                renderAs="H4"
              />

              <Typography
                className={s.lineClamp}
                label={product?.attributes?.heroSubtitle}
                variant="small_m"
                color="text_subheading"
              />
              <div className={s.btn}>
                <Button
                  variant="PrimaryOutline"
                  color="secondary"
                  label="Explore"
                  rightIcon={
                    <ChevronRight
                      primaryColor="interaction_button"
                      width={16}
                      height={16}
                    />
                  }
                  fullwidth
                  size="Small"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
