import Head from 'next/head';
import PropTypes from 'prop-types';

function schemaGenerator(data) {
  const schemaObj = {
    '@context': 'https://schema.org',
    '@type': data?.mainType,
    headline: data?.headline,
    description: data?.description,
    keywords: data?.keywords,
    image: {
      '@type': 'ImageObject',
      url: data?.backgroundImage,
      height: 800,
      width: 1200,
    },
    inLanguage: 'en',
    datePublished: data?.createdAt,
    dateModified: data?.updatedAt,
    author: {
      '@type': 'Person',
      name: data?.author?.name,
      sameAs: `https://parkplus.io/author/${data?.author?.slug}`,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Park+',
      url: 'https://parkplus.io/',
      logo: {
        '@type': 'ImageObject',
        url: 'https://strapi-file-uploads.parkplus.io/park_Logo_ebc88c4a81.png',
        height: 250,
        width: 60,
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': data?.category
        ? `https://parkplus.io/blog/${data?.category}/${data?.slug}`
        : `https://parkplus.io/car-news/${data?.slug}`,
    },
    genre: data?.genre,
    articleBody: data?.articleBody,
    wordCount: data?.articleBody?.split(' ')?.length,
  };
  return schemaObj;
}

function BlogArticle({ data }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schemaGenerator(data)),
        }}
      />
    </Head>
  );
}

BlogArticle.propTypes = {
  name: PropTypes.string,
  mainEntityOfPage: PropTypes.string,
  url: PropTypes.string,
  headline: PropTypes.string,
};

export default BlogArticle;
